$color-red: #ff0033;
$color-orange: #ff6600;
$color-yellow: #ffcc00;
$color-green: #00cc66;
$color-blue: #167de4;
$color-purple: #6600cc;
$color-grey-dim: #f4f4f4;

$color-theme: $color-blue;

$color-test: $color-red;

$btn-border-radius: 0.25em;
$btn-bg: $color-theme;
$btn-color: #fff;
$btn-bg-hover: darken($btn-bg, 5%);
$btn-deactive-bg: #eee;
$btn-deactive-color: #ccc;
$btn-sub-bg: #ccc;
$btn-sub-color: #fff;
$btn-sub-bg-hover: darken($btn-sub-bg, 5%);
$btn-space-between: 0.5em;

$font-red: darken($color-red, 5%);
$font-orange: darken($color-orange, 5%);
$font-yellow: darken($color-yellow, 5%);
$font-green: darken($color-green, 5%);
$font-blue: darken($color-blue, 5%);
$font-purple: darken($color-purple, 5%);

$font-small: 0.875em;
$font-smaller: 0.75em;
$font-smallest: 0.625em;
$font-small-abs: 0.875rem;
$font-smaller-abs: 0.75rem;
$font-smallest-abs: 0.625rem;

$font-normal: 400;
$font-bold: 700;
