#main {
  ul {
    @include clearfix;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 2em;
    li {
      float: left;
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-bottom: 1em;
        border-bottom: 1px solid #eee;
        @include clearfix;
      }
      h2,
      h3,
      p {
        padding: 0 1em;
        border-left: 1px solid #eee;
        margin: 0;
        @media screen and (max-width: 767px) {
          padding: 0;
          border: none;
        }
      }
      h2 {
        margin-top: 0;
        border-color: #000;
      }
      h3 {
        font-size: 0.875em;
        color: $color-theme;
        font-weight: 400;
        padding: 1em;
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
      p {
        font-size: 0.875em;
        color: #888;
      }
      a {
        display: inline-block;
        font-size: 0.75em;
        color: #fff;
        background-color: $color-theme;
        border-radius: 0.25em;
        padding: 0.25em 0.75em;
        opacity: 0.7;
        cursor: pointer;
        margin: 1rem;
        &:hover {
          opacity: 1;
        }
        @media screen and (max-width: 767px) {
          margin: 0;
          margin-top: 0.5em;
          float: right;
        }
      }
    }
  }
}
