#comp-footer {
  background: rgb(244, 244, 245);
  background: -moz-linear-gradient(
    0deg,
    rgba(244, 244, 245, 0) 0%,
    rgba(244, 244, 245, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(244, 244, 245, 0) 0%,
    rgba(244, 244, 245, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(244, 244, 245, 0) 0%,
    rgba(244, 244, 245, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4f4f5",endColorstr="#f4f4f5",GradientType=1);
  font-size: 0.625em;
  color: #888;
  text-align: center;
  border-top: 1px solid #eee;
  margin-top: 5em;
  padding: 3em 0;
  a {
    color: $color-theme;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}
