#bosi {
  h3 {
    font-size: 0.875em;
    color: $color-theme;
    margin-bottom: 0.25em;
    & + p {
      margin-top: 0.25em;
    }
  }
  .tab {
    margin-bottom: 1.5em;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        font-size: 0.875em;
        a {
          display: block;
          padding: 0.5em 0.75em;
          border-radius: 0.25em;
          color: #888;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            background-color: #eee;
          }
        }
        &.active {
          a {
            background-color: $color-theme;
            color: #fff;
            font-weight: 700;
            &:hover {
              background-color: $color-theme;
            }
          }
        }
      }
    }
  }
  table {
    width: 100%;
    border-spacing: 0;
    font-size: 0.875em;
    th,
    td {
      text-align: left;
      vertical-align: top;
    }
    th {
      font-weight: 700;
      color: $color-theme;
      border-left: 1px solid #eee;
      padding-left: 1em;
      width: 8em;
    }
    td {
      padding-bottom: 1.25em;
      span {
        display: block;
        color: #000;
        font-weight: 700;
        margin-bottom: 0.25em;
      }
    }
  }
  .diff {
    list-style: none;
    margin: 0;
    padding: 0;
    @include clearfix;
    li {
      float: left;
      width: 30%;
      margin-right: 5%;
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
      img {
        height: 10em;
        width: auto;
      }
      h4 {
        font-size: 0.875em;
        font-weight: 400;
        color: $color-theme;
        margin-bottom: 0;
      }
      p {
        margin-top: 0.5em;
      }
      @media screen and (max-width: 767px) {
        float: none;
        width: 100%;
        text-align: left;
        img {
          display: none;
        }
        h4 {
          font-weight: 700;
        }
      }
    }
  }
  .usage {
    margin: 0;
    padding-left: 1.5em;
  }
}
