html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Noto Sans KR', sans-serif;
}
* {
  box-sizing: border-box;
}
h1 {
  font-weight: 100;
  font-size: 1.75em;
  margin-top: 0.875em;
}
h2 {
  color: #000;
  font-size: 1em;
  margin-top: 2.5em;
  span {
    font-weight: 400;
  }
}
a {
  cursor: pointer;
}
.wrapper {
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    max-width: 960px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1024px;
  }
  .container {
    // background-color: rgba(#fc0, 0.1);
    padding: 1rem;
  }
}
.section {
  border-bottom: 1px solid #eee;
  padding: 1em 0 3em 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}
