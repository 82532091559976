.board {
  .search {
    .form-column {
      float: right;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      th {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid $color-theme;
        color: $color-theme;
        font-size: 0.75em;
        padding: 1em 0;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid #eee;
        color: #888;
        text-align: center;
        padding: 0.75em 0.25em;
        font-size: 0.875em;
        &.title {
          text-align: left;
          color: #000;
          a {
            color: inherit;
            text-decoration: none;
            cursor: pointer;
            &:hover,
            &:active {
              color: inherit;
              text-decoration: underline;
            }
          }
        }
        .modify {
          display: inline-block;
          font-size: 0.75em;
          padding: 0.5em 0.75em;
          color: #fff;
          background-color: $color-green;
          border-radius: 0.25em;
          cursor: pointer;
          &:hover {
            background-color: darken($color-green, 10%);
          }
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}
.pagenation {
  list-style: none;
  margin: 1em auto;
  padding: 0;
  text-align: center;
  font-size: 0.875em;
  line-height: 1;
  li {
    display: inline-block;
    padding: 0.5em 0.5em;
    cursor: pointer;
    &:hover {
      color: $color-theme;
    }
    &.prev,
    &.next {
      background-color: #f4f4f5;
      color: #888;
      border-radius: 100%;
      padding: 0.25em 0.7em 0.5em 0.7em;
      margin: 0 0.5em;
      font-size: 1rem;
      &:hover {
        background-color: $color-theme;
        color: #fff;
      }
    }
    &.active {
      color: $color-theme;
    }
  }
}
.board-detail,
.press-detail {
  width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      font-size: 0.75em;
      th,
      td {
        text-align: center;
      }
      th {
        border-top: 1px solid #ddd;
        color: $color-theme;
        padding: 1.5em 0 0 0;
      }
      td {
        border-bottom: 1px solid #ddd;
        color: #888;
        padding: 0.5em 0 1.5em 0;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid #ddd;
        color: #888;
        text-align: center;
        padding: 0.75em 0.25em 2em 0.25em;
        font-size: 0.875em;
        text-align: left;
        h1 {
          color: #000 !important;
        }
        p {
          table {
            tbody {
              td {
                padding: 0;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
  .attached {
    display: inline-block;
    font-size: 0.75em;
    color: rgba(#000, 0.5);
    background-color: rgba(#fc0, 0.1);
    border: 1px solid rgba(#fc0, 0.3);
    border-radius: 0.5em;
    padding: 0.25em 0.75em;
    cursor: pointer;
    &:hover {
      color: rgba(#000, 1);
      background-color: rgba(#fc0, 0.3);
    }
  }
  .modify {
    cursor: pointer;
  }
}
.press-detail {
  table {
    thead {
      font-size: 1em;
      th,
      td {
        text-align: left;
      }
      th {
        border-top: 1px solid #ddd;
        color: #000;
        padding: 1.5em 0 0 0;
      }
      td {
        border-bottom: 1px solid #ddd;
        color: $color-theme;
        padding: 0.5em 0 1.5em 0;
        font-size: 0.75em;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid #ddd;
        color: #666;
        text-align: center;
        padding: 0.75em 0.25em 2em 0.25em;
        font-size: 1em;
        text-align: left;
        .img {
          width: 80%;
          margin: 1em auto;
          img {
            width: 100%;
            height: auto;
          }
          span {
            display: block;
            margin-top: 0.25em;
            font-size: 0.75em;
            color: #aaa;
          }
        }
      }
    }
  }
  .to-list {
    display: block;
    margin: 2em auto;
    width: 15em;
    background-color: $color-theme;
    color: #fff;
    border-radius: 0.25em;
    font-size: 0.875em;
    padding: 0.5em 0;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: darken($color-theme, 10%);
    }
  }
}
.btn-wrapper + .board-detail {
  margin-top: 1em;
}
