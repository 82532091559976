#comp-sub-header {
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .wrapper {
    width: 100%;
    background-color: rgba(white, 0.4);
    margin: 0;
    @media screen and (min-width: 768px) {
      max-width: 100%;
    }
    @media screen and (min-width: 1200px) {
      max-width: 100%;
    }
    .container {
      text-align: center;
      padding: 3em 0;
      margin: 0 auto;
    }
  }
  .title {
    font-size: 2em;
    font-weight: 700;
  }
  p {
    font-size: 0.875em;
    @media screen and (min-width: 768px) {
      width: 50%;
      margin: 2em auto 0 auto;
    }
  }
  &.default {
    background-image: url('../../assets/sub-default.jpg');
    background-color: #eee;
    color: rgba(0, 0, 0, 0.8);
    .title {
      font-weight: 100;
    }
  }
  &.main {
    background-image: url('../../assets/bg-main.jpg');
    background-color: #eee;
    color: #fff;
    .wrapper {
      background-color: rgba(0, 0, 0, 0.5);
      .container {
        padding: 8em 0;
      }
    }
  }
}
