#terms {
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: auto;
    &.term-box {
      width: 100%;
      font-size: 0.75em;
      padding: 1em;
      background-color: #f3f3f3;
      border: 1px solid rgba(0, 0, 0, 0.05);
      resize: none;
      height: 30em;
    }
  }
}
