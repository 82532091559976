@import 'mixins/mixin';

@import 'var';
@import 'fonts';
@import 'common';

@import 'comps/board';
@import 'comps/contents';

@import 'comps/comp-footer';
@import 'comps/comp-header';
@import 'comps/comp-side-nav';
@import 'comps/comp-sub-header';

@import 'views/main';
@import 'views/intro';
@import 'views/bosi';
@import 'views/client';
@import 'views/press';
@import 'views/notice';
@import 'views/contact';
@import 'views/terms';

#main {
  /* GRID */
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-start {
    justify-content: flex-start;
    text-align: left;
  }

  .col-center {
    justify-content: center;
    text-align: center;
  }

  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
}
