#comp-header {
  @include clearfix;
  border-bottom: 1px solid rgba(black, 0.05);
  a {
    cursor: pointer;
    color: rgba(black, 0.7);
    text-decoration: none;
    &:hover {
      color: rgba(black, 1);
    }
  }
  .wrapper {
    .container {
      height: 4.5em;
      padding: 1em;
      @media screen and (max-width: 767px) {
        height: 3.5em;
        padding: 0;
      }
    }
  }
  .logo {
    background-image: url("../../assets/logo.png");
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    height: 2.5em;
    width: 7em;
    cursor: pointer;
    span {
      display: none;
      font-size: 1em;
      font-weight: 700;
    }
    @media screen and (max-width: 767px) {
      height: 3.5em;
      // float: none;
      margin: 0 auto;
      background-size: auto 2.5em;
      background-position: left 1em center;
      width: 12em;
      span {
        display: inline-block;
        margin-top: 0.875em;
        margin-left: 4em;
      }
    }
  }
  .menu {
    cursor: pointer;
    font-weight: 700;
  }
  #nav-toggle {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      height: 3.5em;
      width: 3.5em;
      background-image: url("../../assets/icon-nav-toggle.png");
      background-position: center;
      background-size: 1.25em;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  #nav-main {
    @include clearfix;
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;
    .collapse-item {
      float: left;
      .menu {
        display: block;
        padding: 0.75em;
        @media screen and (max-width: 767px) {
          padding: 1em 0.75em;
        }
      }
    }
    .mobile-only {
      height: 3.5em;
      background-color: $color-theme;
      @media screen and (min-width: 768px) {
        display: none;
      }
      a {
        background-image: url("../../assets/icon-home-white.png");
        background-position: center;
        background-size: 1.5em;
        background-repeat: no-repeat;
        display: block;
        width: 3.5em;
        height: 3.5em;
        float: left;
        font-size: 0.875em;
        &:last-child {
          background-image: url("../../assets/icon-close-white.png");
          float: right;
        }
      }
    }
    @media screen and (max-width: 767px) {
      min-height: 100vh;
      width: 290px;
      background-color: white;
      position: absolute;
      top: 0;
      left: -100px;
      opacity: 0;
      z-index: -1;
      .collapse-item {
        display: block;
        float: none;
      }
      & + #nav-overlay {
        display: none;
        content: "";
        width: 100vw;
        height: 100vh;
        position: fixed;
        // z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black, 0.7);
        opacity: 0;
        transition: all 0.2s;
      }
      &.show {
        left: 0;
        opacity: 1;
        z-index: 9999;
        transition: all 0.2s;
        & + #nav-overlay {
          display: block;
          opacity: 1;
        }
      }
    }
  }
  #nav-acc {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 767px) {
      position: absolute;
      top: 0;
      right: 0;
    }
    #nav-acc-sub {
      display: none;
    }
    li {
      display: inline-block;
    }
    &.on {
      position: relative;
      @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        right: 0;
      }
      #nav-acc-sub {
        position: absolute;
        top: 2.5rem;
        right: 0;
        background-color: #fff;
        border-radius: 0.25rem;
        @include box-shadow(0, 0.125em, 0.5em, #000000, 0.2);
        min-width: 11rem;
        margin: 0;
        padding: 0;
        z-index: 9999;
        li {
          width: 100%;
          a {
            display: block;
            padding: 1rem;
            border-bottom: 1px solid #eee;
            @media screen and (min-width: 768px) {
              padding: 0.75rem 1rem;
              font-size: 0.875em;
            }
            &:hover {
              color: $color-theme;
            }
          }
        }
        &.show {
          display: block;
        }
        @media screen and (max-width: 767px) {
          top: 3rem;
          right: 0.5rem;
          font-size: 0.875em;
        }
      }
    }
  }

  .collapse-list {
    list-style: none;
    margin: 0;
    padding: 0;
    .collapse-item {
      position: relative;
      border-bottom: 1px solid #eee;
      @media screen and (min-width: 768px) {
        border: none;
      }
      .collapse-header,
      .collapse-body {
        margin: 0;
        padding: 0;
      }
      .collapse-body {
        height: 0;
        overflow: hidden;
        transition: height 0.2s;
        @media screen and (min-width: 768px) {
          width: 0;
        }
        .collapse-contents {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            a {
              display: block;
              padding: 1rem;
              border-bottom: 1px solid #eee;
              @media screen and (min-width: 768px) {
                padding: 0.75rem 1rem;
                font-size: 0.875em;
              }
              &:hover {
                color: $color-theme;
              }
            }
            &:last-child {
              a {
                border: none;
              }
            }
          }
        }
      }
      &.show-contents {
        .collapse-body {
          height: auto;
          width: auto;
          background-color: #f4f4f5;
          @media screen and (min-width: 768px) {
            height: auto !important;
            min-width: 10rem;
            width: 100%;
            position: absolute;
            top: 2.5rem;
            left: 0.5rem;
            z-index: 9999;
            // padding: 0.5em 0;
            background-color: #fff;
            border-radius: 0.25rem;
            @include box-shadow(0, 0.125em, 0.5em, #000000, 0.2);
          }
        }
      }
    }
  }
}
