#contents {
  color: #555;
  .container {
    @include clearfix;
    .contents-area {
      @media screen and (max-width: 767px) {
        width: auto;
        float: none;
        padding-left: 0;
      }
    }
  }
  #side-nav {
    & + .contents-area {
      float: left;
      width: 80%;
      padding-left: 3em;
      @media screen and (max-width: 767px) {
        width: auto;
        float: none;
        padding-left: 0;
      }
    }
  }
  h1 {
    color: $color-theme;
  }
  p {
    font-size: 0.875em;
  }
}
