#contact {
  a {
    color: $color-theme;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  h5 {
    color: $color-theme;
    margin-top: 2.5em;
    margin-bottom: 0.5em;
  }
  p {
    margin-top: 0;
  }
}
